<template>
    <div>
        <v-alert
                v-model="visibleHelp"
                dismissible
                color="pink"
                border="left"
                elevation="2"
                colored-border
                icon="help"
        >
            <slot/>
        </v-alert>
        <v-btn
                color="pink"
                dark
                small
                v-if="visibleHelp==false"
                bottom
                fixed
                left
                fab
                @click="visibleHelp=true"
        >
            <v-icon>help</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "Help",
        data: function () {
            return {
                visibleHelp: false,
            }
        },
    }
</script>
