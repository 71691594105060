import axios from 'axios'

const SERVER_URL = 'api/locus';

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000
});

export default {
    // (C)reate
    createNew: (name) => instance.post('', {name: name}),
    // (R)ead
    getAll: () => instance.get('', {
        params: {'sort': 'position,asc', 'projection': 'noAlleles', 'size': '100'},
        transformResponse: [function (data) {
            return data ? JSON.parse(data)._embedded.loci : data;
        },]
    }),
    // (U)pdate
    updateForId: (id, name) => instance.put('/' + id, {name: name}),
    // (D)elete
    removeForId: (id) => instance.delete('/' + id),

    // Reorder
    reorder: (id, position) => instance.patch('/' + id, {position: position})
}
